import React from "react";
import style from "./Header.module.css";

const Header = ({ name }) => {
  return (
    <>
      <div className={style.header}>
        <div className={style.innerHeader}>
          <h1 className="bennerfont mt-5" style={{ fontSize: "50px" }}>
            {name}
          </h1>
        </div>
        <div className={style.wavesContainer}>
          <svg
            className={style.waves}
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className={style.parallax}>
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="7"
                fill="var(--theme-color)"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className={style.mobileheader}>
        <div className={style.wavesContainerr}>
          <div className={style.overlay}></div>
          <div className={style.innerHeaderr}>
            <h1 className="bennerfont mt-5 " >
              {name}
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
