// Loader.js
import React from 'react';
import style from './Loader.module.css';

const Loader = () => {
  return (
   <>
  <section className='d-none d-lg-block d-xl-block '>
  <div className={style['loader-container']}>
      <div className={style.loader}>
        <div className={style.square} id={style.sq1}></div>
        <div className={style.square} id={style.sq2}></div>
        <div className={style.square} id={style.sq3}></div>
        <div className={style.square} id={style.sq4}></div>
        <div className={style.square} id={style.sq5}></div>
        <div className={style.square} id={style.sq6}></div>
        <div className={style.square} id={style.sq7}></div>
        <div className={style.square} id={style.sq8}></div>
        <div className={style.square} id={style.sq9}></div>
      </div>
    </div>
  </section>
   </>
  );
}

export default Loader;
