import React, { useState, useEffect } from 'react';
import styles from './UpArrow.module.css'; // Import CSS module for styling
import { FaArrowCircleUp } from 'react-icons/fa';


const UpArrow = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Function to handle scrolling to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Function to show or hide the button based on scroll position
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) { // Adjust this value based on when you want the button to appear
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Add event listener for scroll when the component mounts
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <div>
            {isVisible && 
                <button className={styles.upArrow} onClick={scrollToTop}>
                    <FaArrowCircleUp className={styles.upArrowIcon} />
                </button>
            }
        </div>
    );
};

export default UpArrow;
