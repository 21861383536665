import React from "react";
import { Container } from "react-bootstrap";
import style from "./CErtificate.module.css";
import { CERTIFICATE } from "./CErtificate.utils";

const CErtificate = () => {
  return (
    <>
      <div className="pattern">
        <section
          className="mt-100"
          style={{ marginBottom: "80px" }}
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <Container>
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <h1 className="mainTitle pro mb-5">Our Accreditation</h1>
                </div>
                <div className={style.gridContainer}>
                  {CERTIFICATE.map((item, index) => (
                    <div key={index} className={style.gridItem}>
                      <img
                        src={item.image}
                        alt=""
                        className={style.CErtificateIamge}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default CErtificate;
