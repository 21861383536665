import emailjs from "emailjs-com";
import React, { useState } from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import Header from "../../Common/Header/Header";
import styles from "./Contact.module.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_8t8zo6u",
        "template_7v4cpsd",
        formData,
        "H_ZW0BlMqa2w4z3_D"
      )
      .then(
        (response) => {
          setStatusMessage("SUCCESS! Your message has been sent.");
          console.log("SUCCESS!", response.status, response.text);
          setFormData({
            name: "",
            phone: "",
            email: "",
            message: "",
          });
        },
        (err) => {
          setStatusMessage("FAILED... Something went wrong.");
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <>
      <div className="pattern">
        <Header name="Contact Us" />
        <section className={styles.contactSection}>
          <div className="container">
            <div className=" col-sm-12">
              <h1 className="mainTitle pro">Contact BK Exim</h1>
              <p className="subtitle  text-center mb-5">
                Whether you have questions about our products, need assistance
                with your order, or just want to learn more about BK Exim, we're
                here to help. With over 10 years of experience in the export
                industry, we understand the nuances of international trade and
                are committed to providing you with the best service possible.
                Reach out to us today and let us assist you with your needs.
              </p>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className={styles.contactForm}>
                  <h2 className={styles.companyDetailsTitle}>Get In Touch</h2>
                  <form onSubmit={handleSubmit} Validate>
                    <div className="row">
                      <div className={`col-12 col-md-6 ${styles.formGroup}`}>
                        <label htmlFor="name" className="inter fs-5 fw-normal">
                          Name:
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className={`col-12 col-md-6 ${styles.formGroup}`}>
                        <label htmlFor="phone" className="inter fs-5 fw-normal">
                          Phone:
                        </label>
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className={styles.formGroup}>
                      <label htmlFor="email" className="inter fs-5 fw-normal">
                        Email:
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label htmlFor="message" className="inter fs-5 fw-normal">
                        Message:
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <button type="submit" className="btnCustome">
                      Submit
                    </button>
                  </form>
                  {statusMessage && (
                    <p className={styles.statusMessage}>{statusMessage}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className={styles.companyDetails}>
                  <h2 className={styles.companyDetailsTitle}>
                    Company Details
                  </h2>
                  <div className={styles.detailItem}>
                    <div className={styles.iconRound}>
                      <FaMapMarkerAlt className={styles.icon} />
                    </div>
                    <div>
                      <h3>Address</h3>
                      <p>
                        G-43, International Fashion Market,
                        <br />
                        Nr.Sitanagar Chowk, Punagam
                        <br />
                        Surat, Gujarat-395101, India.
                      </p>
                    </div>
                  </div>
                  <div className={styles.detailItem}>
                    <div className={styles.iconRound}>
                      <FaPhone className={styles.icon} />
                    </div>
                    <div>
                      <h3>Phone</h3>
                      <p>+91 9723405026</p>
                      <p>+91 7990987259</p>
                    </div>
                  </div>
                  <div className={styles.detailItem}>
                    <div className={styles.iconRound}>
                      <FaEnvelope className={styles.icon} />
                    </div>
                    <div>
                      <h3>Email</h3>
                      <p>contact@bkexim.in</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-12">
                <iframe
                  title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3719.8154533916436!2d72.86601667526129!3d21.199488880492883!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDExJzU4LjIiTiA3MsKwNTInMDYuOSJF!5e0!3m2!1sen!2sin!4v1715531989810!5m2!1sen!2sin"
                  width="100%"
                  height="450"

                  style={{ border: "2px solid orange", borderRadius: "10px"}}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
