import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Element, Link } from "react-scroll";
import aduki1 from "../../../../Images/pulses/adzuki-beans-1.webp";
import aduki2 from "../../../../Images/pulses/adzuki-beans-2.jpg";
import aduki3 from "../../../../Images/pulses/adzuki-beans-3.jpg";
import aduki4 from "../../../../Images/pulses/adzuki-beans-4.jpg";
import chick1 from "../../../../Images/pulses/chickpeas-143543-1.jpg";
import chick2 from "../../../../Images/pulses/chickpeas.jpg";
import chick3 from "../../../../Images/pulses/chicks-2.jpg";
import chick4 from "../../../../Images/pulses/chicks.jpg";
import split2 from "../../../../Images/pulses/green-2.jpg";
import split3 from "../../../../Images/pulses/green-3.jpg";
import split1 from "../../../../Images/pulses/green-4.jpg";
import split4 from "../../../../Images/pulses/green.jpg";
import kidney1 from "../../../../Images/pulses/kidnety-4.webp";
import kidney2 from "../../../../Images/pulses/kidney-1.jpg";
import kidney3 from "../../../../Images/pulses/kidney-3.jpg";
import kidney4 from "../../../../Images/pulses/kidney.jpg";
import image1 from "../../../../Images/pulses/main-pulses.webp";
import Mungdal from "../../../../Images/pulses/mandal.webp";
import Mungda2 from "../../../../Images/pulses/moong-dal.jpg";
import mung1 from "../../../../Images/pulses/mung-2.jpg";
import mung2 from "../../../../Images/pulses/mung-3.jpg";
import mung4 from "../../../../Images/pulses/mung-4.jpg";
import Mungda3 from "../../../../Images/pulses/mung-dal-chilka-concept-m-5ef34c87ab6d4.png";
import Mungda4 from "../../../../Images/pulses/mung-dal.webp";
import mung3 from "../../../../Images/pulses/mungbeans.jpg";
import image2 from "../../../../Images/pulses/red-lentil-1.webp";
import image3 from "../../../../Images/pulses/red-lentil-2.jpg";
import image4 from "../../../../Images/pulses/red-lentil-3.jpg";
import image5 from "../../../../Images/pulses/red-lentil-4.jpg";
import Header from "../../../Common/Header/Header";
import style from "../Productdetail.module.css";

const Pulses = () => {
  const [activeSection, setActiveSection] = useState("red");
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };
  return (
    <>
      <Header name={"Product Details"} />
      <div class="pattern">
        <section className="mt-100 mb-lg-5">
          <Container>
            <div className="row">
              <div className="col-md-6 position-relative">
                <img
                  src={image1}
                  alt=""
                  className="w-100 h-100 "
                  style={{ objectFit: "cover" }}
                />
                <div className={style.overlay}></div>
              </div>
              <div className="col-md-5  ms-lg-5">
                <h1 className="maintitle pro mt-2 mt-lg-0 ">Pulses</h1>
                <p className="para-desc mx-auto mb-5 inter">
                Pulses are an essential source of plant-based protein, fiber, and essential nutrients, making them a staple in many diets worldwide. They are versatile, nutritious, and form the basis of countless traditional dishes. Here, we delve into some popular types of pulses that are commonly used in cooking.
                </p>

                <ul className="d-flex flex-wrap align-items-center justify-content-center gap-3">
                  <li>
                    <button
                      className={style.btnn}
                      onClick={() =>{ navigate("/product"); scrollToTop();}}
                    >
                      All Products
                    </button>
                  </li>

                  <li>
                    <Link to="red" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Red Lentils</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="aduki" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Aduki Beans</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="split" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Split Green Peas</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="chick" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Chick Peas</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="kidney" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Kidney Beans</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="mungda" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Mung Dal</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="mung" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Mung Beans</button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Element name="red">
              <div className="row mt-5 d-flex" id="red">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Red Lentils</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Red lentils are small, split lentils that cook quickly and have a mild, slightly sweet flavor. They are a key ingredient in many Middle Eastern and Indian dishes, such as dals and soups. Rich in protein, fiber, and iron, red lentils are not only nutritious but also easy to digest, making them a great addition to a healthy diet.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={image2} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={image3} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={image4} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={image5} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="aduki">
              <div className="row mt-lg-5 d-flex " id="aduki">
                <div className="col-md-5 mt-lg-5  order-first order-lg-last">
                  <h1 className="maintitle pro ">Aduki Beans</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Aduki beans, also known as adzuki beans, are small, reddish-brown beans with a sweet, nutty flavor. They are popular in East Asian cuisine, particularly in desserts and sweet dishes like red bean paste. Aduki beans are high in protein, fiber, and essential nutrients, offering numerous health benefits including improved digestion and heart health.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={aduki1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={aduki2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={aduki3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={aduki4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="split">
              <div className="row mt-lg-5 d-flex" id="split">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Split Green peas</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Split green peas are the dried, peeled, and split seeds of peas. They are commonly used in soups, such as the classic split pea soup, and stews due to their creamy texture when cooked. Packed with protein, fiber, and vitamins, split green peas are an excellent choice for a hearty and nutritious meal.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={split1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={split2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={split3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={split4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="chick">
              <div className="row mt-lg-5 d-flex" id="chick">
                <div className="col-md-5 mt-lg-5  order-first order-lg-last">
                  <h1 className="maintitle pro ">Chick Peas</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Chickpeas, also known as garbanzo beans, are round, beige legumes with a slightly nutty taste. They are incredibly versatile and can be used in a variety of dishes, including hummus, salads, stews, and curries. Chickpeas are rich in protein, fiber, and various vitamins and minerals, making them a staple in vegetarian and vegan diets.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={chick1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={chick2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={chick3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={chick4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="kidney">
              <div className="row mt-lg-5 d-flex" id="kidney">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Kidney Beans</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Kidney beans are large, red beans known for their robust flavor and meaty texture. They are commonly used in chili, stews, and salads. Kidney beans are a great source of protein, fiber, iron, and folate, contributing to their status as a nutritious and satisfying food. They must be cooked properly to eliminate toxins and ensure safety.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={kidney1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={kidney2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={kidney3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={kidney4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="mungda">
              <div className="row mt-lg-5 d-flex" id="mungda">
                <div className="col-md-5 mt-lg-5  order-first order-lg-last">
                  <h1 className="maintitle pro ">Mung Dal</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Mung dal refers to split mung beans, which are often used in Indian cooking. They have a mild flavor and cook relatively quickly, making them ideal for soups, stews, and dals. Mung dal is rich in protein, fiber, and essential nutrients, providing a hearty and healthy addition to meals.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={Mungda2} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={Mungdal} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={Mungda3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={Mungda4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="mung">
              <div className="row mt-lg-5 d-flex" id="mung">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Mung Beans</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Mung beans are small, green beans that are widely used in Asian cuisine. They can be sprouted, cooked whole, or split and hulled. Mung beans are highly nutritious, offering a good source of protein, fiber, vitamins, and minerals. They are often used in salads, soups, stir-fries, and even desserts.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={mung1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={mung2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={mung3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={mung4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Pulses;
