import image2 from "../../../../Images/license/APEDA.png";
import image3 from "../../../../Images/license/FSSAI.png";
import image4 from "../../../../Images/license/ISO.png";
import image5 from "../../../../Images/license/MSME.png";
import image1 from "../../../../Images/license/SpiceBoard.png";
import image6 from "../../../../Images/license/TradMark.png";
export const CERTIFICATE = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
  {
    image: image5,
  },
  {
    image: image6,
  },
];
