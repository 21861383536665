import React from "react";
import Header from "../../Common/Header/Header";
import { Products } from "../Home/Products";

const Product = () => {
  return (
    <>
      <Header name={"Our Products"} />
      <Products />
    </>
  );
};

export default Product;
