import React, { useState } from "react";
import Header from "../../../Common/Header/Header";
import { Container } from "react-bootstrap";
import coco1 from "../../../../Images/Fruits/coco-1.jpg";
import coco2 from "../../../../Images/Fruits/coco-2.jpg";
import coco3 from "../../../../Images/Fruits/coco-3.jpg";
import coco4 from "../../../../Images/Fruits/coco-4.jpg";
import pemo1 from "../../../../Images/Fruits/pemo-1.jpg";
import pemo2 from "../../../../Images/Fruits/pemo-2.jpg";
import pemo3 from "../../../../Images/Fruits/pemo-3.jpg";
import pemo4 from "../../../../Images/Fruits/pomegranate-3383814_1920.jpg";
import image1 from "../../../../Images/Fruits/main-fruit.jpg";
import image2 from "../../../../Images/Fruits/banana-2.jpg";
import image3 from "../../../../Images/Fruits/banana-3.jpg";
import image4 from "../../../../Images/Fruits/banana.jpg";
import image5 from "../../../../Images/Fruits/grenn-bananas-palm-cultivation-fruits-tenerife-island-plantation-young-unripe-banana-with-palm-leaves-shallow-depth-field-closeup.jpg";
import style from "../Productdetail.module.css";
import { useNavigate } from "react-router-dom";
import { Link, Element } from "react-scroll";

const Fruits = () => {
  const [activeSection, setActiveSection] = useState("pemo");
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };

  return (
    <>
      <Header name={"Product Details"} />
      <div class="pattern">
        <section className="mt-100 mb-lg-5">
          <Container>
            <div className="row">
              <div className="col-md-6 position-relative">
                <img
                  src={image1}
                  alt=""
                  className="w-100 h-100 "
                  style={{ objectFit: "cover" }}
                />
                <div className={style.overlay}></div>
              </div>
              <div className="col-md-5  ms-lg-5">
                <h1 className="maintitle pro  mt-2 mt-lg-0">Fruits</h1>
                <p className="para-desc mx-auto mb-5 inter">
                Fruits are nature's candy, offering a delightful combination of sweetness, nutrition, and health benefits. They come in an array of colors, flavors, and textures, each providing essential vitamins, minerals, and antioxidants. Let's explore some exquisite fruits that are loved by many.
                </p>

                <ul className="d-flex flex-wrap align-items-center justify-content-center gap-3">
                  <li>
                    <button
                      className={style.btnn}
                      onClick={() =>{ navigate("/product"); scrollToTop();}}
                    >
                      All Products
                    </button>
                  </li>
                  <li>
                    <Link to="pemo" smooth={true} duration={100}>
                      <button onClick={scrollToTop} >Pomegranate</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="coco" smooth={true} duration={100}>
                      <button onClick={scrollToTop} >Semi Husk Coconut</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="banana" smooth={true} duration={100}>
                      <button onClick={scrollToTop} >Cavendish Banana</button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <Element name="pemo">
              <div className="row mt-5 d-flex" id="pemo">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Pomegranate</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Pomegranates are known for their vibrant red seeds, called arils, which are bursting with juicy, sweet-tart flavor. These fruits are rich in antioxidants, vitamins C and K, and fiber, making them a powerhouse of nutrition. Pomegranates can be enjoyed fresh, juiced, or used as a garnish in salads, desserts, and savory dishes. Their unique flavor and health benefits have made them a beloved ingredient in both culinary and medicinal traditions.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={pemo1} alt="" />
                  </div>
                  <div className={style.medium}>
                    <img src={pemo2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={pemo3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={pemo4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="coco">
              <div className="row mt-lg-5 d-flex " id="coco">
                <div className="col-md-5 mt-lg-5  order-first order-lg-last">
                  <h1 className="maintitle pro">Semi Husk Coconut</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Semi husk coconuts are a versatile tropical fruit known for their refreshing water and rich, creamy flesh. The water inside a semi husk coconut is hydrating and packed with electrolytes, making it a popular natural beverage. The flesh can be eaten fresh, grated, or used to make coconut milk, oil, and various desserts. Coconuts are also valued for their nutritional content, including healthy fats, vitamins, and minerals that support overall health.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={coco1} alt="" />
                  </div>
                  <div className={style.medium}>
                    <img src={coco2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={coco3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={coco4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="banana">
              <div className="row mt-lg-5 d-flex" id="banana">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Cavendish Banana</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  The Cavendish banana is the most widely consumed banana variety globally, known for its smooth texture and sweet flavor. This fruit is a convenient snack that is rich in potassium, vitamin B6, and dietary fiber. Cavendish bananas are versatile and can be eaten on their own, added to smoothies, baked into breads and cakes, or used in savory dishes. Their natural sweetness and nutritional benefits make them a favorite among all age groups.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={image2} alt="" />
                  </div>
                  <div className={style.medium}>
                    <img src={image3} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={image4} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={image5} alt="" />
                  </div>
                </div>
              </div>
            </Element>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Fruits;
