import { Route, Routes } from "react-router-dom";
import "./App.css";
import Loader from "./Components/Common/Loader/Loader";
import UpArrow from "./Components/Common/UpArrow/UpArrow";
import Footer from "./Components/Layout/Footer/Footer";
import NAvbar from "./Components/Layout/NAvbar/NAvbar";
import { Topbar } from "./Components/Layout/Topbar";
import About from "./Components/Pages/About/About";
import Contact from "./Components/Pages/Contact/Contact";
import Home from "./Components/Pages/Home/Home";
import Product from "./Components/Pages/Product/Product";
import { Productdetail } from "./Components/Pages/Productdetail";
import Service from "./Components/Pages/Service/Service";

import { useEffect, useState } from "react";
import Bricks from "./Components/Pages/Productdetail/Bricks/Bricks";
import Fruits from "./Components/Pages/Productdetail/Fruits/Fruits";
import Pulses from "./Components/Pages/Productdetail/Pulses/Pulses";
import Vegetables from "./Components/Pages/Productdetail/Vegetables/Vegetables";

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate data fetching
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      const fetchData = async () => {
        // Simulate a network request
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setLoading(false);
        localStorage.setItem("hasVisited", "true");
      };

      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader /> // Show the loader while loading
      ) : (
        <>
          <Topbar />
          <NAvbar />
          <UpArrow />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product-detail" element={<Productdetail />} />
            <Route path="/product-detail/vegetables" element={<Vegetables />} />
            <Route path="/product-detail/bricks" element={<Bricks />} />
            <Route path="/product-detail/pulses" element={<Pulses />} />
            <Route path="/product-detail/fruits" element={<Fruits />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>

          <Footer />
        </>
      )}
    </>
  );
}

export default App;
