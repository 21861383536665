import React from "react";
import Carousel from "react-bootstrap/Carousel";
import banner4 from "../../../../Images/Fruits/main-fruit.jpg";
import banner2 from "../../../../Images/pulses/main-pulses.webp";
import banner1 from "../../../../Images/rice-hero-1-@2x.jpg";
import banner3 from "../../../../Images/vegetables/main-vegetables.webp";
import style from "./Banner.module.css";

const Banner = () => {
  return (
    <>
      <section>
        <Carousel interval={3000}>
          <Carousel.Item>
            <div className={style.bannerContainer}>
              <img src={banner1} alt="" className={style.bannerheight} />
              <div className={style.overlay}></div>
              <Carousel.Caption>
                <h2 className="bennerfont" style={{ fontSize: "70px" }}>
                  Premium Rice
                </h2>

                {/* <p className="mt-3 fs-5">
                {" "}
                We offer a wide range spices like cardamom, black pepper, clove,
                etc...{" "}
              </p> */}
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={style.bannerContainer}>
              <img src={banner2} alt="" className={style.bannerheight} />
              <div className={style.overlay}></div>
              <Carousel.Caption>
                <h2 className="bennerfont" style={{ fontSize: "70px" }}>
                  Nutritious Pulses
                </h2>

                {/* <p className="mt-3 fs-5">
                {" "}
                We offer a wide range spices like cardamom, black pepper, clove,
                etc...{" "}
              </p> */}
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={style.bannerContainer}>
              <img src={banner3} alt="" className={style.bannerheight} />
              <div className={style.overlay}></div>
              <Carousel.Caption>
                <h2 className="bennerfont" style={{ fontSize: "70px" }}>
                  Fresh Vegetables
                </h2>

                {/* <p className="mt-3 fs-5">
                {" "}
                We offer a wide range spices like cardamom, black pepper, clove,
                etc...{" "}
              </p> */}
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={style.bannerContainer}>
              <img src={banner4} alt="" className={style.bannerheight} />
              <div className={style.overlay}></div>
              <Carousel.Caption>
                <h2 className="bennerfont" style={{ fontSize: "70px" }}>
                  Organic Fruits
                </h2>

                {/* <p className="mt-3 fs-5">
                {" "}
                We offer a wide range spices like cardamom, black pepper, clove,
                etc...{" "}
              </p> */}
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
};

export default Banner;
