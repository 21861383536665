import React from "react";
import AboutUs from "./AboutUs/AboutUs";
import Banner from "./Banner/Banner";
import Blankiamge from "./Blankiamge/Blankiamge";
import CErtificate from "./CErtificate/CErtificate";
import Products from "./Products/Products";
import { Service } from "./Service";
import Testimonial from "./Testimonial/Testimonial";

const Home = () => {
  return (
    <>
      <Banner />
      <AboutUs />
      <Products />
      <Blankiamge />
      <Service />
      <Testimonial />
      <CErtificate />
    </>
  );
};

export default Home;
