import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import chilli1 from "../../../../Images/vegetables/chilli-1.jpg";
import chilli2 from "../../../../Images/vegetables/chilli-2.jpg";
import chilli3 from "../../../../Images/vegetables/chilli-3.jpg";
import chilli4 from "../../../../Images/vegetables/chilli-4.jpg";
import drum2 from "../../../../Images/vegetables/drum-1.webp";
import drum1 from "../../../../Images/vegetables/drum-2.webp";
import drum3 from "../../../../Images/vegetables/drum-3.png";
import drum4 from "../../../../Images/vegetables/drum-4.webp";
import ele1 from "../../../../Images/vegetables/elephant-1.webp";
import ele2 from "../../../../Images/vegetables/elephant-2.jpg";
import ele3 from "../../../../Images/vegetables/elephant-3.webp";
import ele4 from "../../../../Images/vegetables/elephant-4.webp";
import garlic1 from "../../../../Images/vegetables/garlic-1.jpg";
import garlic2 from "../../../../Images/vegetables/garlic-2.jpg";
import garlic3 from "../../../../Images/vegetables/garlic-4461533_1920.jpg";
import garlic4 from "../../../../Images/vegetables/garlic-5572882_1920.jpg";
import image1 from "../../../../Images/vegetables/main-vegetables.webp";
import image2 from "../../../../Images/vegetables/onion-1430062_1920.jpg";
import image3 from "../../../../Images/vegetables/onion-2.jpg";
import image4 from "../../../../Images/vegetables/onion-3.jpg";
import image5 from "../../../../Images/vegetables/onion-red-food_1200x1200.jpg";
import Header from "../../../Common/Header/Header";
import style from "../Productdetail.module.css";

const Vegetables = () => {
  const [activeSection, setActiveSection] = useState("onion");
  const navigate = useNavigate();
  return (
    <>
      <Header name={"Product Details"} />
      <div class="pattern">
        <section className="mt-100 mb-lg-5">
          <Container>
            <div className="row">
              <div className="col-md-6 position-relative">
                <img
                  src={image1}
                  alt=""
                  className="w-100 h-100 "
                  style={{ objectFit: "cover" }}
                />
                <div className={style.overlay}></div>
              </div>
              <div className="col-md-5  ms-lg-5">
                <h1 className="maintitle pro mt-2 mt-lg-0">Vegetables</h1>
                <p className="para-desc mx-auto mb-5 inter">
                Vegetables are an essential part of a balanced diet, providing vital nutrients, vitamins, and minerals that support overall health and well-being. They come in a diverse array of types and flavors, each bringing unique benefits to the table. Here, we explore some popular vegetables that are integral to many culinary traditions.
                </p>

                <ul className="d-flex flex-wrap align-items-center justify-content-center gap-3">
                  <li>
                    <button
                      className={style.btnn}
                      onClick={() => navigate("/product")}
                    >
                      All Products
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setActiveSection("onion")}>
                      Onion
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setActiveSection("chilli")}>
                      Green Chilli
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setActiveSection("garlic")}>
                      Garlic
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setActiveSection("drumstick")}>
                      Drumstick
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setActiveSection("elephant")}>
                      Elephant Yam
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <Element name="onion">
              <div className="row mt-5 d-flex" id="onion">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Onion</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Onions are a kitchen staple known for their pungent flavor and versatility in cooking. They come in various types, including red, white, and yellow onions, each with its unique taste and use. Onions are often used as a base for soups, stews, and sauces, imparting a rich depth of flavor. They can be eaten raw in salads, sautéed for a caramelized sweetness, or fried for a crunchy topping.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={image2} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={image3} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={image4} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={image5} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="chilli">
              <div className="row mt-lg-5 d-flex" id="chilli">
                <div className="col-md-5 mt-lg-5  order-first order-lg-last">
                  <h1 className="maintitle pro ">Green Chilli</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Green chillies add a fiery kick to dishes and are widely used in many cuisines, particularly in Indian, Mexican, and Thai cooking. They can be used fresh, dried, or pickled, and are essential for adding heat and complexity to salsas, curries, and marinades. Besides their spicy flavor, green chillies are also rich in vitamins A and C, contributing to their nutritional value.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={chilli1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={chilli2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={chilli3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={chilli4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="garlic">
              <div className="row mt-lg-5 d-flex" id="garlic">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Garlic</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Garlic is a powerhouse of flavor and nutrition, known for its strong aroma and distinctive taste. It is used in various forms, including fresh cloves, garlic powder, and garlic paste. Garlic enhances the flavor of countless dishes, from pasta sauces and stir-fries to roasted meats and dressings. Additionally, garlic has been celebrated for its health benefits, including its antimicrobial properties and potential to support heart health.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={garlic1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={garlic2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={garlic3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={garlic4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="drumstick">
              <div className="row mt-lg-5 d-flex" id="drumstick">
                <div className="col-md-5 mt-lg-5  order-first order-lg-last">
                  <h1 className="maintitle pro ">Drumstick</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Drumstick, also known as moringa, is a nutrient-dense vegetable commonly used in South Asian cuisine. The long, slender pods are often added to soups, curries, and stews, where they impart a mild, slightly sweet flavor. Drumsticks are rich in vitamins, minerals, and antioxidants, making them a valuable addition to a healthy diet. The leaves and flowers of the drumstick tree are also edible and highly nutritious.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={drum1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={drum2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={drum3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={drum4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="elephant">
              <div className="row mt-lg-5 d-flex" id="elephant">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Elephant yam</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Elephant yam, also known as suran or jimikand, is a starchy tuber that is popular in Indian cooking. It has a dense texture and a mildly earthy flavor, making it suitable for various preparations, including curries, stir-fries, and chips. Elephant yam is a good source of dietary fiber and essential nutrients like potassium and vitamin C. Its unique texture and taste add a distinct element to traditional dishes.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={ele1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={ele2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={ele3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={ele4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Vegetables;
