import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import image1 from "../../../../Images/bricks/bricks-1.jpg";
import image2 from "../../../../Images/bricks/bricks-2.jpg";
import image3 from "../../../../Images/bricks/bricks-3.webp";
import image4 from "../../../../Images/bricks/bricks-4.jpg";
import image5 from "../../../../Images/bricks/bricks-5.webp";
import Header from "../../../Common/Header/Header";
import style from "../Productdetail.module.css";

const Bricks = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };
  return (
    <>
      <Header name={"Product Details"} />
      <div class="pattern">
        <section className="mt-100 mb-lg-5">
          <Container>
            <div className="row">
              <div className="col-md-6 position-relative">
                <img
                  src={image1}
                  alt=""
                  className="w-100 h-100 "
                  style={{ objectFit: "cover" }}
                />
                <div className={style.overlay}></div>
              </div>
              <div className="col-md-5  ms-lg-5">
                <h1 className="maintitle pro mt-2 mt-lg-0 ">Bricks</h1>
                <p className="para-desc mx-auto mb-5 inter">
                Bricks are fundamental building materials used in construction due to their durability, strength, and thermal properties. They come in various types and sizes, each designed for specific construction needs and architectural styles. Here, we explore different types of bricks commonly used in building projects.
                </p>
                <ul className="d-flex">
                  <li>
                    <button
                      className={style.btnn}
                      onClick={() => { navigate("/product"); scrollToTop(); }}
                    >
                      All Products
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-lg-5 d-flex">
              <div className="col-md-5 mt-5 d-none d-lg-block">
                <h1 className="maintitle pro ">All Type Of Bricks</h1>
                <p className="para-desc mx-auto mb-lg-5 inter">
                Clay bricks are the most traditional and widely used type of bricks. Made from natural clay and fired at high temperatures, these bricks offer excellent strength and durability. They are known for their thermal insulation properties and ability to withstand harsh weather conditions. Clay bricks are commonly used in residential and commercial construction for walls, facades, and pathways.
                </p>
                <p className="para-desc mx-auto mb-lg-5 inter">
                Fly Ash Bricks: Fly ash bricks are made from fly ash, a byproduct of coal combustion, mixed with lime and gypsum. These bricks are eco-friendly, lightweight, and have a high compressive strength. Fly ash bricks are known for their uniform shape and size, which makes them easy to work with and ideal for load-bearing walls and multi-story buildings.
                </p>
                <p className="para-desc mx-auto mb-lg-5 inter">
                Concrete Bricks: Concrete bricks are made from cement, sand, and water, offering high durability and strength. They come in various sizes and shapes, making them versatile for different construction applications. Concrete bricks are often used in foundations, retaining walls, and exterior facades due to their robust nature and ability to withstand environmental stress.
                </p>
                <p className="para-desc mx-auto mb-lg-5 inter">
                Engineering Bricks: Engineering bricks are specially manufactured to have high compressive strength and low water absorption. They are typically used in applications where strength and durability are paramount, such as in foundations, retaining walls, and damp-proof courses. These bricks are often characterized by their smooth surface and uniform shape.
                </p>
                <p className="para-desc mx-auto mb-lg-5 inter">
                Sand Lime Bricks: Sand lime bricks are made from a mixture of sand, lime, and water, which is then pressed and autoclaved. These bricks have a smooth finish, good acoustic insulation, and high strength. They are often used in load-bearing walls, facades, and interior construction due to their aesthetic appeal and durability.
                </p>
                <p className="para-desc mx-auto mb-lg-5 inter">
                Fire Bricks: Fire bricks, also known as refractory bricks, are designed to withstand high temperatures and are used in the construction of fireplaces, kilns, and furnaces. Made from fireclay, these bricks have excellent thermal insulation properties and are capable of enduring extreme heat without cracking or breaking.
                </p>
                <p className="para-desc mx-auto mb-lg-5 inter">
                Perforated Bricks: Perforated bricks have holes or perforations that reduce their weight and improve their insulation properties. They are commonly used in non-load-bearing walls, partition walls, and cladding. The perforations also make these bricks more economical and easier to handle during construction.
                </p>
                
              </div>
              <div className={`col-md-7 ${style.masonary}`}>
                <div className={style.large}>
                  <img src={image2} alt="" />
                </div>

                <div className={style.medium}>
                  <img src={image3} alt="" />
                </div>
                <div className={style.large}>
                  <img src={image4} alt="" />
                </div>
                <div className={style.small}>
                  <img src={image5} alt="" />
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Bricks;
