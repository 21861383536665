import React from "react";
import { Container } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import logo from "../../../Images/Logo/LOGO.png";
// import logo from "../../../Images/Logo/250x100.png";
import style from "./Footer.module.css";

const Footer = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="pattern">
        <section className={style.FooterFlex}>
          <Container>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                {/* <h3 className={style.FooterHeading}>BK Exim</h3> */}
                <div className={style.LogoContainer}>
                  <img src={logo} alt="" className={style.Logo} />
                </div>
                <div className={style.SocialIcons}>
                  <a
                    href="https://www.facebook.com/people/BK-EXIM/61560448312993/?mibextid=ZbWKwL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook />
                  </a>
                  <a
                    href="https://www.facebook.com/people/BK-EXIM/61560448312993/?mibextid=ZbWKwL"  
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://www.facebook.com/people/BK-EXIM/61560448312993/?mibextid=ZbWKwL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </div>
                <p className={style.FooterTextt}></p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h3 className={style.FooterHeading}>Quick Links</h3>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/");
                    scrollToTop();
                  }}
                >
                  Home
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/about");
                    scrollToTop();
                  }}
                >
                  About Us
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/product");
                    scrollToTop();
                  }}
                >
                  Products
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/service");
                    scrollToTop();
                  }}
                >
                  Service
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/contact");
                    scrollToTop();
                  }}
                >
                  Contact
                </p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h3 className={style.FooterHeading}>Our products</h3>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/product-detail");
                    scrollToTop();
                  }}
                >
                  Rice
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/product-detail/bricks");
                    scrollToTop();
                  }}
                >
                  Bricks
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/product-detail/fruits");
                    scrollToTop();
                  }}
                >
                  Fresh Fruits
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/product-detail/vegetables");
                    scrollToTop();
                  }}
                >
                  Fresh Vegetables
                </p>
                <p
                  className={style.FooterText}
                  onClick={() => {
                    navigate("/product-detail/pulses");
                    scrollToTop();
                  }}
                >
                  Pulses
                </p>
              </div>
              <div className="col-lg-3 col-sm-6">
                <h3 className={style.FooterHeading}>Contact Us</h3>
                <p className={style.FooterText}>
                  <FaPhoneAlt
                    style={{ marginRight: "10px", color: "var(--button" }}
                  />
                  9723405026
                </p>
                <p className={style.FooterText}>
                  <FaPhoneAlt
                    style={{ marginRight: "10px", color: "var(--button" }}
                  />
                  7990987259
                </p>
                <p className={style.FooterText}>
                  <FaMapMarkerAlt
                    style={{ marginRight: "10px", color: "var(--button" }}
                  />
                  Surat , Gujrat, India
                </p>
                <p className={style.FooterText}>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@bkexim.in"
                    target="_blank"
                    className="text-white"
                  >
                    <FaEnvelope
                      style={{ marginRight: "10px", color: "var(--button" }}
                    />
                    contact@bkexim.in
                  </a>
                </p>
              </div>
            </div>
            <hr style={{ color: "white" }} />
            <div className="d-flex justify-content-center ">
              <span
                className="text-center  position-relative"
                style={{
                  color: "var(--button)",
                }}
              >
                Copyright © 2024 BK Exim. All rights reserved |
              </span>
              <span
                className="text-center  position-relative ms-1"
                style={{
                  color: "white",
                }}
              >
                Powered by: BK Exim
              </span>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Footer;
