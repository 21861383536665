import React from "react";
import style from "./Blankiamge.module.css";

const Blankiamge = () => {
  return (
    <>
      <div className="pattern">
        <section className="mt-100">
          <div className={style.imageFlex}>
            <div className={style.overlay}></div>
            <div className={style.text}>
              <h1>We Provide Best Quality of Goods which you need</h1>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blankiamge;
