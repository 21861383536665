import React from "react";
import { Container } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaFacebook, FaMapMarked } from "react-icons/fa";
import { FaEnvelope, FaInstagram, FaLinkedin, FaPhone } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const navigate = useNavigate();
  return (
    <>
      <section>
        <div class="bg-green text-white py-3">
          <Container>
            <div class="row align-items-center">
              <div class="col-6  text-md-left ">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item mr-md-4">
                    <FaMapMarked
                      className="fs-5 me-1"
                      onClick={() => navigate("/contact")}
                      style={{ cursor: "pointer" }}
                    />{" "}
                    |
                  </li>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-email">
                        <a
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@bkexim.in"
                          className="text-white "
                          target="_blank"
                        >
                          contact@bkexim.in
                        </a>
                      </Tooltip>
                    }
                  >
                    <li className="list-inline-item mr-md-4">
                      <FaEnvelope
                        className="fs-5 me-1"
                        style={{ cursor: "pointer" }}
                      />{" "}
                      |
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-phone">
                        <span>Call us at: +91 97234 05026</span>
                      </Tooltip>
                    }
                  >
                    <li className="list-inline-item mr-md-4">
                      <FaPhone style={{ cursor: "pointer" }} />
                    </li>
                  </OverlayTrigger>
                </ul>
              </div>
              <div class="col-6  text-md-right text-end pe-4">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item mr-3">
                    <a href="https://www.facebook.com/people/BK-EXIM/61560448312993/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <FaFacebook className="fs-5" style={{ color: "white" }} />
                    </a>
                  </li>
                  <li class="list-inline-item ms-1">
                    <a href="#">
                      <FaLinkedin className="fs-5" style={{ color: "white" }} />
                    </a>
                  </li>
                  <li class="list-inline-item ms-1">
                    <a href="#">
                      <FaInstagram
                        className="fs-5"
                        style={{ color: "white" }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Topbar;
