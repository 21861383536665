import image2 from "../../../Images/cbde3a9c99f95ded2f589988a5c0c589.jpg";
import image1 from "../../../Images/fabc017bff62c94c3fd808ea6da146e1.jpg";
import image3 from "../../../Images/png-clipart-flag-of-malaysia-flag-and-coat-of-arms-of-selangor-national-flag-flag-miscellaneous-flag.png";
import image4 from "../../../Images/singapore-large-flag-11547888236sjmdaxdpob.png";
import image5 from "../../../Images/united-arab-emirates-large-flag-11547887215wotycz6qq6.png";
export const FLAG = [
  {
    image: image1,
    name: "UK",
  },
  {
    image: image2,
    name: "Thailand",
  },
  {
    image: image3,
    name: "Malaysia",
  },
  {
    image: image4,
    name: "Singapore",
  },
  {
    image: image5,
    name: "UAE",
  },
];
