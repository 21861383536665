import React from "react";
import { Container } from "react-bootstrap";
import Header from "../../Common/Header/Header";
import Services from "../Home/Service/Services";
import style from "./Service.module.css";
import { FLAG } from "./Service.utils";

const Service = () => {
  return (
    <>
      <div className="pattern">
        <Header name={"Our Services"} />
        <Services />
        <div className="mt-5">
          <Container>
            <div className="row">
              <div className="col-12">
                <div className="text-center mb-lg-5">
                  <h1 className="mainTitle pro ">Geo-Specific Services</h1>
                  <p className="subtitle text-center">
                    At BK Exim, we understand the diverse needs of our global
                    clients. Our services are tailored to meet the specific
                    demands of each region, ensuring the highest quality and
                    satisfaction.
                  </p>
                </div>
                <div className={style.gridContainer}>
                  {FLAG.map((item, index) => (
                    <div key={index} className={style.gridItem}>
                      <img
                        src={item.image}
                        alt=""
                        className={style.CErtificateIamge}
                      />
                      <span className={style.CErtificateName}>{item.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Service;
