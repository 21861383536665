import React from "react";
import { Container } from "react-bootstrap";
import image1 from "../../../Images/Fruits/main-fruit.jpg";
import image3 from "../../../Images/fresh-vegetable.jpg";
import image2 from "../../../Images/pulses/main-pulses.webp";
import image4 from "../../../Images/rice-hero-1-@2x.jpg";
import Header from "../../Common/Header/Header";

const About = () => {
  return (
    <>
      <div className="pattern">
        <Header name={"About Us"} />
        <section className="mt-100 mb-5">
          <Container>
            <div className="row">
              <div className="col-12 col-lg-6" style={{ paddingRight: "50px" }}>
                <div class="contentRight">
                  <div class="content">
                    <h4 className="subtitle" style={{ color: "var(--button)" }}>
                      Welcome To
                    </h4>
                    <h1 className="maintitle pro">BK Exim</h1>
                    <p
                      style={{
                        textAlign: "justify",
                        fontFamily: "inter",
                        fontSize: "18px",
                      }}
                    >
                      BK EXIM, established SINCE 2020 in Surat, Gujarat, India,
                      has swiftly risen to prominence as a leading export
                      company specializing in the international trade of
                      vegetables, fruits, and groceries. The driving force
                      behind this thriving enterprise is Mr. KALPESH THUMMAR,
                      the visionary Founder and CEO of BK EXIM. With a
                      relentless commitment to delivering top-quality products,
                      the company has rapidly earned a reputation for excellence
                      in the global market. From its inception, BK EXIM has
                      singularly focused on one goal: to export the very best in
                      vegetables, fruits, and groceries to customers worldwide.
                      Under Mr. thummar’s astute leadership, the company has
                      consistently set high standards, meticulously sourcing and
                      distributing premium agricultural products that
                      consistently meet and exceed the expectations of its
                      discerning clientele across the globe.BK EXIM owes much of
                      its success to its strategic location in Surat, Gujarat,
                      which boasts a wealth of agricultural resources. This
                      geographical advantage, coupled with the company’s
                      unwavering dedication to quality, has positioned it as a
                      trusted partner in the international supply chain. bk exim
                      is renowned for its reliability and commitment to
                      excellence, making it the preferred choice for those
                      seeking the finest agricultural products on a global
                      scale.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="contentLeft  pt-110">
                  <div className="row">
                    <div className="imgWrapper">
                      <img src={image4} alt />
                    </div>
                    <div className="imgWrapper">
                      <img src={image1} alt />
                    </div>
                    <div className="imgWrapper">
                      <img src={image3} alt />
                    </div>
                    <div className="imgWrapper">
                      <img src={image2} alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </>
  );
};

export default About;
