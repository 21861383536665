import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Element, Link } from "react-scroll";
import r11211 from "../../../Images/Rice/1121-1.webp";
import r11212 from "../../../Images/Rice/1121-2.jpg";
import r11213 from "../../../Images/Rice/1121-3.jpg";
import r11214 from "../../../Images/Rice/1121-3.webp";
import pr1061 from "../../../Images/Rice/pr-106-1.jpeg";
import pr1 from "../../../Images/Rice/pr-11-1.webp";
import pr2 from "../../../Images/Rice/pr-11-2.webp";
import pr3 from "../../../Images/Rice/pr-11-3.webp";
import pr4 from "../../../Images/Rice/pr-11-4.jpg";
import pr142 from "../../../Images/Rice/pr-14-.jpg";
import pr141 from "../../../Images/Rice/pr-14-1.webp";
import pr143 from "../../../Images/Rice/pr-14-2.webp";
import pr144 from "../../../Images/Rice/pr-14-4.jpeg";
import sharbati1 from "../../../Images/Rice/sharbati-rice-1.avif";
import sharbati2 from "../../../Images/Rice/sharbati-rice-2.webp";
import sharbati3 from "../../../Images/Rice/sharbati-rice-3.jpeg";
import sharbati4 from "../../../Images/Rice/sharbati-rice-4.jpeg";
import image1 from "../../../Images/r-1.jpg";
import image2 from "../../../Images/r-2.jpg";
import image3 from "../../../Images/r-3.jpg";
import image4 from "../../../Images/r-4.jpg";
import image5 from "../../../Images/r-5.jpg";
import Header from "../../Common/Header/Header";
import style from "./Productdetail.module.css";

const Productdetail = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };

  return (
    <>
      <Header name={"Product Details"} />
      <div className="pattern">
        <section className="mt-100 mb-lg-5">
          <Container>
            <div className="row">
              <div className="col-md-6 position-relative">
                <img
                  src={image1}
                  alt=""
                  className="w-100 h-100 "
                  style={{ objectFit: "cover" }}
                />
                <div className={style.overlay}></div>
              </div>
              <div className="col-md-5 ms-lg-5">
                <h1 className="maintitle pro mt-2 mt-lg-0">Rice</h1>
                <p className="para-desc mx-auto mb-5  inter">
                Rice is a fundamental staple in many cuisines around the world, known for its versatility and nutritional benefits. This grain comes in various types, each with unique characteristics and uses. Whether it's a fragrant basmati rice or a sturdy PR14, rice forms the foundation of countless dishes, providing sustenance and flavor to millions.
                </p>
                <ul className="d-flex flex-wrap align-items-center justify-content-center gap-3">
                  <li>
                    <button
                      className={style.btnn}
                      onClick={() => { navigate("/product") ; scrollToTop();}}
                    >
                      All Products
                    </button>
                  </li>
                  <li>
                    <Link to="traditional" smooth={true} duration={100}>
                      <button onClick={scrollToTop} >Traditional Basmati</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="1121" smooth={true} duration={100}>
                      <button onClick={scrollToTop} >1121 Rice</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="sharbati" smooth={true} duration={100}>
                      <button onClick={scrollToTop}>Sharbati Basmati</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="pr106" smooth={true} duration={100}>
                      <button onClick={scrollToTop} >PR106</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="pr11" smooth={true} duration={100}>
                      <button  onClick={scrollToTop} >PR11</button>
                    </Link>
                  </li>
                  <li>
                    <Link to="pr14" smooth={true} duration={100}>
                      <button onClick={scrollToTop} >PR14</button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <Element name="traditional">
              <div className="row mt-5 d-flex" id="traditional">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Traditional Basmati Rice</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Renowned for its fragrant aroma and delicate texture, Basmati Rice is a staple in many Indian and Middle Eastern dishes. This long-grain rice variety is often used in biryanis, pilafs, and side dishes, bringing a distinct flavor and an elegant touch to any meal. Its unique ability to elongate when cooked sets it apart from other rice varieties, making it a preferred choice for festive and special occasions.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={image2} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={image3} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={image4} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={image5} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="1121">
              <div className="row mt-lg-5 d-flex " id="1121">
                <div className={`col-md-7  ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={r11211} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={r11212} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={r11213} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={r11214} alt="" />
                  </div>
                </div>
                <div className="col-md-5 mt-lg-5 order-first order-lg-last">
                  <h1 className="maintitle pro ">1121 Rice</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Known for its extra-long grains, 1121 Rice is a type of Basmati rice that stands out for its length and non-sticky texture. It is perfect for making luxurious dishes like pulao and biryani, where the grains remain separate and fluffy after cooking. The 1121 variety is highly sought after for its exceptional cooking qualities and ability to absorb flavors, enhancing the overall dining experience.
                  </p>
                </div>
              </div>
            </Element>
            <Element name="sharbati">
              <div className="row mt-lg-5 d-flex" id="sharbati">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">Sharbati Basmati Rice</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  Sarbati Rice is a premium non-basmati rice variety that is known for its affordability and quality. It is characterized by its long grains and subtle flavor, making it a versatile choice for daily meals, including steamed rice, fried rice, and rice-based desserts. Its soft texture and pleasant taste make it a popular choice among households looking for both quality and value.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={sharbati1} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={sharbati2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={sharbati3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={sharbati4} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="pr106">
              <div className="row mt-lg-5 d-flex" id="pr106">
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={pr1061} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={image3} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={image4} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={image5} alt="" />
                  </div>
                </div>
                <div className="col-md-5 mt-lg-5 order-first order-lg-last">
                  <h1 className="maintitle pro ">PR 106 Rice</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  PR106 Rice is a high-yielding, medium-grain variety commonly grown in the Indian subcontinent. This rice is known for its excellent milling quality and soft texture, making it ideal for a variety of dishes, including curries, casseroles, and plain boiled rice. Its versatility and consistent performance make it a reliable option for both home cooks and professional chefs.
                  </p>
                </div>
              </div>
            </Element>
            <Element name="pr11">
              <div className="row mt-lg-5 d-flex" id="pr11">
                <div className="col-md-5 mt-lg-5">
                  <h1 className="maintitle pro ">PR 11 Rice</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  PR11 Rice is another popular medium-grain variety known for its robustness and adaptability to different climatic conditions. It is often used in everyday cooking due to its consistent quality, soft texture, and ability to absorb flavors well, making it perfect for savory dishes and rice bowls. PR11 Rice is valued for its dependable cooking properties, ensuring a satisfying meal every time.
                  </p>
                </div>
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={pr4} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={pr2} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={pr3} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={pr1} alt="" />
                  </div>
                </div>
              </div>
            </Element>
            <Element name="pr14">
              <div className="row mt-lg-5 d-flex" id="pr14">
                <div className={`col-md-7 ${style.masonary}`}>
                  <div className={style.large}>
                    <img src={pr141} alt="" />
                  </div>

                  <div className={style.medium}>
                    <img src={pr142} alt="" />
                  </div>
                  <div className={style.large}>
                    <img src={pr143} alt="" />
                  </div>
                  <div className={style.small}>
                    <img src={pr144} alt="" />
                  </div>
                </div>
                <div className="col-md-5 mt-lg-5 order-first order-lg-last">
                  <h1 className="maintitle pro ">PR 14 Rice</h1>
                  <p className="para-desc mx-auto mb-lg-5 inter">
                  PR14 Rice is a versatile medium-grain rice variety that is appreciated for its good cooking qualities and texture. It is commonly used in home kitchens and restaurants for a range of recipes, including soups, stews, and traditional rice dishes, due to its soft and fluffy texture when cooked. The consistent quality and ease of preparation make PR14 a favored choice for a variety of culinary applications.
                  </p>
                </div>
              </div>
            </Element>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Productdetail;
