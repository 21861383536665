import React from "react";
import { Container } from "react-bootstrap";
import {
  FaQuoteRight,
  FaStar,
  FaStarHalfAlt
} from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import image2 from "../../../../Images/Testimonials/jenny.jpg";
import image3 from "../../../../Images/Testimonials/Ahemed.jpg";
import image4 from "../../../../Images/Testimonials/Trivedi.jpg";
import image1 from "../../../../Images/Testimonials/Fredy.jpg";
import styles from "./Testimonial.module.css";

const Testimonial = ({ name, position, message }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
   

  return (
    <>
      <div className="pattern">
        <div className="section-title mt-100">
          <h1 className="maintitle pro">Testimonial</h1>
          <p className="para-desc mx-auto subtitle">
            Read what our satisfied clients and partners have to say about our
            quality products and reliable services.
          </p>
          <Container>
            <Slider {...settings}>
              <div>
                <div className={styles.testimonial}>
                  <div className={styles.testimonialAuthor}>
                    <div>
                      <img
                        src={image1}
                        alt={name}
                        className={styles.authorImage}
                      />
                    </div>
                    <div className={styles.authorDetails}>
                      <h3 className={styles.authorName}>Mr.Fredy D.</h3>
                      <div className="d-flex">
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStarHalfAlt className={styles.star} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.testimonialContent}>
                    <p className={styles.testimonialMessage}>
                      Choosing BK Exim for our supply chain needs has been one
                      of the best decisions for our company. Their dedication to
                      delivering high-quality products consistently is
                      commendable. The timely delivery ensures we never face
                      stockouts, and their customer service is always ready to
                      assist with any queries.{" "}
                      <span>
                        <FaQuoteRight
                          style={{ fontSize: "25px", color: "var(--button)" }}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.testimonial}>
                  <div className={styles.testimonialAuthor}>
                    <div>
                      <img
                        src={image2}
                        alt={name}
                        className={styles.authorImage}
                      />
                    </div>
                    <div className={styles.authorDetails}>
                      <h3 className={styles.authorName}>Ms.Jenny P.</h3>
                      <div className="d-flex">
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStarHalfAlt className={styles.star} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.testimonialContent}>
                    <p className={styles.testimonialMessage}>
                      BK Exim has been a game-changer for our business
                      operations. Their professionalism and commitment to
                      excellence are evident in every interaction. From the
                      initial inquiry to the final delivery, the entire process
                      is seamless and efficient. Their support team is available
                      24/7, providing assistance and ensuring all our
                      requirements are met promptly.{" "}
                      <span>
                        <FaQuoteRight
                          style={{ fontSize: "25px", color: "var(--button)" }}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.testimonial}>
                  <div className={styles.testimonialAuthor}>
                    <div>
                      <img
                        src={image3}
                        alt={name}
                        className={styles.authorImage}
                      />
                    </div>{" "}
                    <div className={styles.authorDetails}>
                      <h3 className={styles.authorName}>Mr.Ahmed A.</h3>
                      <div className="d-flex">
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        {/* <FaStar className={styles.star} /> */}
                        {/* <FaStar className={styles.star} /> */}
                        <FaStarHalfAlt className={styles.star} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.testimonialContent}>
                    <p className={styles.testimonialMessage}>
                      Working with BK Exim has been an outstanding experience.
                      Their attention to detail and focus on customer
                      satisfaction are truly exceptional. The quality of the
                      products they supply is of the highest standard, and their
                      ability to deliver on time, every time, is remarkable. We
                      appreciate their transparent communication and proactive
                      approach in addressing any potential issues.{" "}
                      <span>
                        <FaQuoteRight
                          style={{ fontSize: "25px", color: "var(--button)" }}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.testimonial}>
                  <div className={styles.testimonialAuthor}>
                    <div>
                      <img
                        src={image4}
                        alt={name}
                        className={styles.authorImage}
                      />
                    </div>{" "}
                    <div className={styles.authorDetails}>
                      <h3 className={styles.authorName}>Mr.Trivedi</h3>
                      <div className="d-flex">
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStar className={styles.star} />
                        <FaStarHalfAlt className={styles.star} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className={styles.testimonialContent}>
                    <p className={styles.testimonialMessage}>
                      The support and service we receive from BK Exim have
                      helped us maintain a steady supply chain and meet our
                      business goals effectively. We trust BK Exim as a reliable
                      partner and highly recommend their services to others in
                      the industry. The transparency in their processes gives us
                      confidence and peace of mind.{" "}
                      <span>
                        <FaQuoteRight
                          style={{ fontSize: "25px", color: "var(--button)" }}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
