import {
  faArrowUpRightDots,
  faAward,
  faHeadphones,
  faTruck
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Service = () => {
  return (
    <>
      <div className="pattern">
        <section className="mt-100">
          <div className=" pb-lg-5">
            <div className="container">
              <div className="row">
                <div className=" col-sm-12">
                  <h1 className="mainTitle pro">Why Choose BK Exim?</h1>
                  <p className="subtitle  text-center mb-5">
                    When you choose BK Exim, you'll benefit from over a decade
                    of expertise in the export industry. Our commitment to
                    delivering high-quality products, providing 24/7 support,
                    ensuring timely deliveries, and driving business growth sets
                    us apart. Partner with us to experience unparalleled service
                    and excellence in every interaction.
                  </p>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="item">
                    <span className="icon feature_box_col_four">
                      <FontAwesomeIcon
                        icon={faAward}
                        style={{ fontSize: "45px" }}
                      />
                    </span>
                    <h5 className="high text-uppercase mb-2 ">
                      High Quality Products
                    </h5>
                    <span className="inter">
                      we provide best quality products in the market.{" "}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="item">
                    <span className="icon feature_box_col_four">
                      <FontAwesomeIcon
                        icon={faHeadphones}
                        style={{ fontSize: "45px" }}
                      />
                    </span>
                    <h5 className="high text-uppercase mb-2">24/7 SUPPORT</h5>
                    <span className="inter">
                      Our dedicated team is available around the clock to assist
                      you.{" "}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="item">
                    <span className="icon feature_box_col_four">
                      <FontAwesomeIcon
                        icon={faTruck}
                        style={{ fontSize: "40px" }}
                      />
                    </span>
                    <h5 className="high text-uppercase mb-2">
                      We Deliver On Time
                    </h5>
                    <span className="inter">
                      Timely delivery is our commitment to our clients.
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="item">
                    <span className="icon feature_box_col_four">
                      <FontAwesomeIcon
                        icon={faArrowUpRightDots}
                        style={{ fontSize: "40px" }}
                      />
                    </span>
                    <h5 className="high text-uppercase mb-2">
                      Business Growth
                    </h5>
                    <span className="inter">
                      We help your business grow with our top-notch services.{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Service;
