import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
// import logo from "../../../Images/Logo/High Resolution.png";
import logo from "../../../Images/Logo/250x100.png";

const NAvbar = () => {
  return (
    <Navbar expand="lg" className="">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            alt=""
            className="img-fluid logo"
            style={{ height: "100px" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
            <img
            src={logo}
            alt=""
            className="img-fluid logo"
            style={{ height: "70px" }}
          />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <hr />
            <Nav className="justify-content-end flex-grow-1">
              <Nav.Link href="/" className="pe-3">
                Home
              </Nav.Link>
              <Nav.Link href="/about" className="pe-3">
                About Us
              </Nav.Link>
              <Nav.Link href="/service" className="pe-3">
                Service
              </Nav.Link>
              <Nav.Link href="/product" className="pe-3">
                Product
              </Nav.Link>
              <Nav.Link href="/contact" className="pe-3">
                Contact
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default NAvbar;
