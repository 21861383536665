import React from "react";
import { useNavigate } from "react-router-dom";
import banner6 from "../../../../Images/Besan_600x.webp";
import banner4 from "../../../../Images/Fruits/main-fruit.jpg";
import banner5 from "../../../../Images/bricks/bricks-1.jpg";
import banner1 from "../../../../Images/pulses/main-pulses.webp";
import banner2 from "../../../../Images/r-1.jpg";
import banner3 from "../../../../Images/vegetables/main-vegetables.webp";

const Products = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  };
  return (
    <>
      <section
        className="mt-100  pb-lg-5 pt-lg-5 "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="pattern">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="text-center">
                  <div class="section-title">
                    <h1 className="maintitle pro">Our Trending Products</h1>
                  </div>
                  <p className="para-desc mx-auto mb-5 subtitle">
                    Explore our most sought-after products, renowned for their
                    exceptional quality and reliability. Discover why BK Exim is
                    a trusted name in the industry, providing you with the best
                    in class.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex  flex-wrap gap-lg-5">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="product-grid position-relative">
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "20px",
                        right: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          padding: "5px 20px",
                          background: "var(--button)",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          fontFamily: "Yatra One",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        Rice
                      </span>
                    </div>
                    <div className="product-image">
                      <img
                        className=""
                        src={banner2}
                        alt=""
                        onClick={() => {
                          navigate("/product-detail");
                          scrollToTop();
                        }}
                        style={{ height: "300px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="product-grid position-relative">
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "20px",
                        right: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          padding: "5px 20px",
                          background: "var(--button)",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          fontFamily: "Yatra One",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        Vegitables
                      </span>
                    </div>
                    <div className="product-image">
                      <img
                        className="pic-1"
                        src={banner3}
                        alt=""
                        style={{ height: "300px" }}
                        onClick={() => {
                          navigate("/product-detail/vegetables");
                          scrollToTop();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="product-grid position-relative">
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "20px",
                        right: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          padding: "5px 20px",
                          background: "var(--button)",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          fontFamily: "Yatra One",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        Fruits
                      </span>
                    </div>
                    <div className="product-image">
                      <img
                        className="pic-1"
                        src={banner4}
                        alt=""
                        style={{ height: "300px" }}
                        onClick={() => {
                          navigate("/product-detail/fruits");
                          scrollToTop();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="product-grid position-relative">
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "20px",
                        right: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          padding: "5px 20px",
                          background: "var(--button)",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          fontFamily: "Yatra One",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        Bricks
                      </span>
                    </div>
                    <div className="product-image">
                      <img
                        className="pic-1"
                        src={banner5}
                        alt=""
                        style={{ height: "300px" }}
                        onClick={() => {
                          navigate("/product-detail/bricks");
                          scrollToTop();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="product-grid position-relative">
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "20px",
                        right: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          padding: "5px 20px",
                          background: "var(--button)",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          fontFamily: "Yatra One",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        Pulses
                      </span>
                    </div>
                    <div className="product-image">
                      <img
                        className="pic-1"
                        src={banner1}
                        alt=""
                        style={{ height: "300px" }}
                        onClick={() => {
                          navigate("/product-detail/pulses");
                          scrollToTop();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="product-grid position-relative">
                    <div
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        top: "20px",
                        right: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          padding: "5px 20px",
                          background: "var(--button)",
                          borderRadius: "5px",
                          fontWeight: "bold",
                          fontFamily: "Yatra One",
                          fontSize: "20px",
                          letterSpacing: "1px",
                        }}
                      >
                        Besan
                      </span>
                    </div>
                    <div className="product-image">
                      <img
                        className="pic-1"
                        src={banner6}
                        alt=""
                        style={{ height: "300px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Products;
